import React from 'react'
import { useState } from 'react';
import style from '../Contact/Css/Contact.module.css'
import Button2 from '../../Components/Button/Button2'
import { FaInstagram } from "react-icons/fa";
import emailjs from '@emailjs/browser'

import pix from '../../Imgs/pagamentos/pix.png'
import credito from '../../Imgs/pagamentos/cartoes.png'

const Contact = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    function sendEmail (e) {
        e.preventDefault()

        if (name === '' || email === '' || message === '') {
            alert('Preencha todos os campos')
            return
        }

        const templateParams = {
            from_name: name,
            message: message,
            email: email
        }
        emailjs.send("service_pr26a09", "template_57jig7e", templateParams, "DpCr0w8vC37B7ukbH")
        .then((response) => {
            console.log('Email enviado', response.status, response.text)
            setName('')
            setEmail('')
            setMessage('')
        }, (err) => {
            console.log('Erro', err)
        })

    }

  return (
    <div className={style.contact} id='contact'>

        <div className={style.contactLeft}>
            <h1>
                Contato
            </h1>

            <div className={style.atendimento}>
                <h1>
                    Horário de atendimento
                </h1>
                <p>Segunda-feira à Sexta-Feira: 09:00 às 19:00</p>
                <p>Sábado: 09:00 às 16:00</p>
                <p>Domingo: Fechado</p>

                <Button2
                text={'Agende sua consulta Online'}
                link={'https://wa.me/+554799124904?text=Ol%C3%A1%20Dr.%C2%AA%20Silvia%2C%20gostaria%20de%20agendar%20uma%20sess%C3%A3o.'}
                />
                <h2>Aceito PIX e Cartão de Crédito</h2>
                <a href="https://www.instagram.com/silviamorlandi" target='_blank'>
                
                <FaInstagram size={50} alt="Meu Instagram"/>
                </a>

            </div>
        </div>

        

        <form className={style.form} onSubmit={sendEmail} id='faq'>
            <h1>
                Tem dúvidas?<br/>
                Deixe uma mensagem!
            </h1>
        <input 
          className={style.input}
          type="text"
          placeholder="Digite seu nome"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        
        <input 
          className={style.input}
          type="text"
          placeholder="Digite seu email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />

        <textarea 
          className={style.textarea}
          placeholder="Digite sua mensagem..."
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        />

        <input className={style.button} type="submit" value="Enviar" />
        
        <div className={style.rodape}>
            <p>
            <div>© 2024 Drª Silvia Orlandi - <strong>CBPC 2022/2282</strong></div><br/>
                <div>Desenvolvido por <a href="https://www.instagram.com/walace_ramoss/" target='_blank'>Walace Ramos</a></div>
            </p>
        </div>
      </form>


    </div>
  )
}

export default Contact
