import React from "react";
import style from "./Css/Service.module.css";
import Button from "../../Components/Button/Button";
import Silvia from "../../Imgs/10.jpg";

const About = () => {
  return (
    <div className={style.about} id="atendimento">

      <div>
        <h2>Sobre o atendimento</h2>
      </div>

      <div className={style.infoAtendimento}>

      <img src={Silvia} alt="" className={style.image1}/>
        
        <p>
          "Ofereço um atendimento online acolhedor e personalizado, pensado para
          o seu bem-estar emocional, diretamente no conforto da sua casa. Com
          flexibilidade de horários e total privacidade, estou aqui para te
          ajudar a alcançar uma vida mais equilibrada e feliz.<br/> Vamos começar
          essa transformação juntos? 
          
          Agende a sua sessão e dê o primeiro passo para o seu bem-estar."
        </p>

        <img src={Silvia} alt="" className={style.image2}/>
      </div>

      <div className={style.botao}>
        <Button 
        text={"Agendar Sessão"} 
        link={'https://wa.me/+554799124904?text=Ol%C3%A1%20Dr.%C2%AA%20Silvia%2C%20gostaria%20de%20agendar%20uma%20sess%C3%A3o.'}
        />
        </div>
    </div>
  );
};

export default About;
