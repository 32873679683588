import React from 'react'
import style from './Css/Testmonials.module.css'
import Cards from '../../Components/Cards/Cards'


const Testmonials = () => {
  return (
    <div className={style.testmonials} id='cards'>
            <h2>
                Depoimentos de Pacientes
            </h2>

        <div className={style.cardsPacients}> 

            <Cards
            text={'Gostaria de expressar minha sincera gratidão à Terapeuta Silvia pelo apoio inestimável que tem me oferecido durante minha luta contra a depressão. Sua empatia, compreensão e abordagem cuidadosa têm sido cruciais para meu progresso. A orientação dela tem sido uma luz em momentos difíceis, e não consigo expressar o quanto sou grata por seu trabalho dedicado e eficaz. A ajuda que ela tem me proporcionado é verdadeiramente transformador e estou profundamente apreciada.'}
            name={'- Danny -'}
            />
    
            <Cards
            text={'Boa noite Drª. Primeira consulta ontem e já estou me sentindo leve, como se tivesse tirado um caminhão de cima de mim, mais tranquila, mais confiante... Obrigada'}
            name={'- Elisangêla -'}
            />
    
            <Cards
            text={'Gostaria de agradecer a vc Silvia Orlandi  pela ajuda que tem dado a mim com as sessões de terapia,os métodos que vc tem usado comigo estão sendo muito benefícios meu dia a dia,a epnose tem me trazido recordações de traumas que eu mesma não lembrava mais,e assim estamos conseguindo tratar.Muito obrigado,seu trabalho está sendo muito importante para mim e para minha família.'}
            name={'- Jaqueline Gomes -'}
            />
            
        </div>
    </div>
  )
}

export default Testmonials
