import "./App.css";
import Service from "./Sections/Service/Service";
import Navigation from "./Sections/Navigation/Navigation";
import Presentation from "./Sections/Presentation/Presentation";
import AboutMe from "./Sections/About/AboutMe";
import Testmonials from "./Sections/Testimonials/Testmonials";
import Contact from "./Sections/Contact/Contact";
import NavigationMobile from "./Sections/Navigation/NavigationMobile";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Presentation />
      <AboutMe/>
      <Service />
      <Testmonials/>
      <Contact/>
      <NavigationMobile/>
    </div>
  );
}

export default App;
