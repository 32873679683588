import React from 'react'
import style from  '../Cards/Css/Cards.module.css'
import { RiDoubleQuotesL } from "react-icons/ri";

const Cards = ({text, name}) => {
  return (
    <div className={style.cards}>

        <div className={style.iconSVG}>
          <RiDoubleQuotesL size={30}/>
        </div>

      <div className={style.containerTitle}>
        <h3>
          {text}
        </h3>
      </div>

      <div>
        <p>
          {name}
        </p>
      </div>

    </div>
  )
}

export default Cards
