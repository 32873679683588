import React from 'react'
import style from './Css/About.module.css'
import Silvia from '../../Imgs/04.jpg'

const AboutMe = () => {
  return (
    <div className={style.about}>

        <div>
            <h2 id='about'>
                Sobre Mim
            </h2>
        </div>

      <div className={style.aboutMe}>
        
        <p>
        "Olá! Sou terapeuta, casada, mãe de um filho, e moro em Joinville, SC. Minha jornada na psicologia começou ao cuidar do meu filho com TDAH e comportamento opositor, o que me inspirou a ajudar outras pessoas.<div className={style.parte2}> Sou pós-graduada em Psicanálise e especializada em Terapia Comportamental Cognitiva, Psicoterapia e Diagnóstico. Já ajudei mulheres a superar a depressão, especialmente em fases difíceis como o divórcio. Estou aqui para apoiar você na busca por equilíbrio e bem-estar.</div>"
        </p>

        <img src={Silvia} alt="Drª Silvia Orlandi"/>

      </div>

      

      <div>
        
      </div>
    </div>
  )
}

export default AboutMe
