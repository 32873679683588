import React from 'react'
import style from '../Presentation/Css/Presentation.module.css'
import Atendimento from '../../Imgs/Atendimento.jpg'
import logo from '../../Imgs/logo.png'
import Button from '../../Components/Button/Button'


const Presentation = () => {
  return (
    <div className={style.presentation} id='presentation'>

        <div className={style.atendimento}>
            <img src={Atendimento} alt="Atendimento online" />
        </div>
            
        <div className={style.right}>
            <p>
                Atendimento Online
            </p>

            <img src={logo} width={200} alt="" className={style.logo}/>

            <h2>
            "Cure a mente,<br/>liberte o coração."
            </h2>

            <Button 
            text={'Agendar consulta Online'}
            link={'https://api.whatsapp.com/send/?phone=%2B554799124904&text=Ol%C3%A1+Dr.%C2%AA+Silvia%2C+gostaria+de+agendar+uma+sess%C3%A3o.&type=phone_number&app_absent=0'}
            />


        </div>
        
    </div>
  )
}

export default Presentation
