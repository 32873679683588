import React from 'react'
import style from '../Navigation/Css/NavigationMobile.module.css'
import { SiAboutdotme } from "react-icons/si";
import { MdMedicalServices } from "react-icons/md";
import { ImQuotesLeft } from "react-icons/im";
import { BsFillTelephoneInboundFill } from "react-icons/bs";
import { FaQuestionCircle } from "react-icons/fa";

import { LaptopMinimal } from 'lucide-react';
import { Mail } from 'lucide-react';
import { MessageSquareMore } from 'lucide-react';
import { PhoneOutgoing } from 'lucide-react';
import { Heart } from 'lucide-react';



const NavigationMobile = () => {
  return (
    <div className={style.navigationMobile}>
        <ul>
                
                <a href="#atendimento" alt='Atendimentos'>
                    <li>
                    <LaptopMinimal size={35}/>
                    </li>
                    
                </a>
                <a href="#cards" alt='Depoimentos'>
                    <li>
                    <MessageSquareMore size={35}/>
                    </li>
                    
                </a>
                <a href="#about" alt='Sobre mim'>
                    <li>
                    <Heart size={35}/>
                    </li>
                    
                </a>
                <a href="#contact" alt='Contato'>
                    <li>
                    <PhoneOutgoing size={35}/>
                    </li>
                    
                </a>
                <a href="#faq" alt='Tire sua dúvida'>
                    <li>
                    <Mail size={35}/>
                    </li>
                    
                </a>
            </ul>
    </div>
    )
}

export default NavigationMobile
