import React from 'react'
import style from '../Button/Css/Button2.module.css'

const Button2 = ({ text, link }) => {
  return (
    <div>

      <a href={link} target='_blank'>
        <button className={style.btn2}>
            {text}
        </button>
      </a>
    </div>
  )
}

export default Button2
