import React from 'react'
import style from '../Navigation/Css/Navigation.module.css'

const Navigation = () => {
  return (
    <div className={style.navigation}>
        <div>
            <h3 className={style.title}>
                Drª. Silvia Orlandi<br/><span>Terapeuta</span>
            </h3>
        </div>

        <div>
            <ul>
                <a href="#about">
                    <li>
                        Sobre mim
                    </li>
                </a>
                <a href="#atendimento">
                    <li>
                        Atendimento
                    </li>
                </a>
                <a href="#cards">
                    <li>
                        Depoimentos
                    </li>
                </a>
                <a href="#contact">
                    <li>
                        Contato
                    </li>
                </a>
                <a href="#faq">
                    <li>
                        FAQ
                    </li>
                </a>
            </ul>
        </div>

    </div>
    )
}

export default Navigation
